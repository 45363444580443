<template>
     <div>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <section class="col-lg-12 connectedSortable mt-3 pr-0 pl-0 mb-3">
                        <div class="card">
                            <div class="card-body table-responsive table-head">
                                <div class="row bor-bot pb-3">
                                    <div class="col-md-8 pl-0">
                                        <h1 class="m-0 text-dark pt-2 pb-2">Groups List</h1>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="text-right pb-3">
                                            <ol class="breadcrumb float-sm-right">
                                                <li class="breadcrumb-item">
                                                <a href="/admin/dashboard">Home</a>
                                                </li>
                                                <li class="breadcrumb-item"><Breadcrumbs /></li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                                <custom-alert v-if="displayAlert==true" :message="alertMessage" v-on:showedAlert="resetAlert" ></custom-alert>                                   
                                <div class="row">        
                                    <div class="col-lg-8 mr-0 pr-0">
                                        <div class="mt-3">
                                        <b-form inline>
                                        <label class="mr-sm-2 font-weight-normal">Show</label>
                                        <b-form-select
                                            id="inline-form-custom-select-pref"
                                            class="mb-2 mr-sm-2 mb-sm-0"
                                            v-model="perPage"
                                            :options="viewEntryOptions"
                                            size="sm"
                                        ></b-form-select
                                        >entries
                                        </b-form>
                                        </div>                                    
                                    </div>
                                    <div class="col-lg-4  text-right pr-0">
                                        <div class=" card-text d-flex align-items-center float-right"> 
                                            <div class="mt-3"> 
                                                <b-input-group size="sm">
                                                <b-form-input
                                                    id="filter-input"
                                                    v-model="filter"
                                                    type="search"
                                                    placeholder="Search"
                                                ></b-form-input>
                                                </b-input-group>
                                            </div>
                                            <div class="mt-3 ml-3">                                               
                                               <router-link class="float-sm-right btn btn-blue" to="group/add" tag="b-button"> <i class="fa fa-plus" aria-hidden="true"></i> Add Group</router-link>
                                            </div>
                                        </div>
                                    </div>                                     
                                </div>
                                <b-table
                                    ref="groupTable"
                                    id="group-table"
                                    :items="groups"
                                    :fields="groupTbl"
                                    :per-page="perPage"
                                    :current-page="currentPage"                                     
                                    class="mt-3"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                                    :busy="!loaded"
                                    hover
                                    show-empty
                                >   
                                    <template #cell(name)="row">
                                      {{ row.item.name }}
                                    </template>
                                    <template #cell(permission)="row">
                                      {{ row.item.permission }}
                                    </template>
                                    <template #cell(tags)="row">
                                      {{ row.item.tags }}
                                    </template>
                                    <template #cell(actions)="row">
                                        <div class="text-left py-0 align-middle">
                                            <div class="btn-group btn-group-sm">
                                                <router-link :to="`group/edit/${row.item.id}`" class="btn btn-primary ml-1"> <i class="fas fa-pencil-alt"></i></router-link>
                                                <router-link :to="`group/add-members/${row.item.id}`" class="btn btn-primary ml-1"><i class="fas fa-users" aria-hidden="true"></i></router-link> 
                                                <button class="ml-1 btn btn-danger" @click="deleteRow(row.item.id)"><i class="fas fa-trash"></i></button>
                                            </div>  
                                        </div>                                 
                                    </template>
                                    <template #empty="scope">
                                        <h4 class="text-center">{{ scope.emptyText }}</h4>
                                    </template>                                             
                                    <template #table-busy>
                                        <div class="text-center text-primary my-2">
                                            <b-spinner class="align-middle"></b-spinner>
                                            <strong>Loading...</strong>
                                        </div>
                                    </template>
                                </b-table>
                                <br />
                                <span v-if="loaded">Showing {{ (( currentPage * perPage) - perPage) + 1  }} to {{ (currentPage * perPage) > rows ? rows : currentPage * perPage }} of {{ rows }} entries</span>
                                <b-pagination v-if="loaded && this.groups.length"
                                    v-model="currentPage"
                                    :total-rows="rows"
                                    :per-page="perPage"
                                    prev-text="Previous"
                                    next-text="Next"
                                    aria-controls="groupTable"
                                    class="float-right custom-color"
                                    hide-goto-end-buttons>
                                </b-pagination>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>
     </div>
</template>

<script>
    import * as $ from "jquery"; 
    import moment from "moment";
    import axios from 'axios';
    import Select2 from "v-select2-component";
    import CustomAlert from "../../CustomAlert";

    const validateName = name => {
        if (name == null || name == '') {
            return { valid: false, error: "This field is required" };
        }
        return { valid: true, error: null };
    };

    const validatecheckbox = chkbox => {
        if (chkbox == null || chkbox==0 || chkbox == '') {
            return { valid: false, error: "This field is required" };
        }
        return { valid: true, error: null };
    };

    export default {
        name: 'Group List',
        components: { CustomAlert },
        data() {
            return {
                groups:[],
                tags:[],
                permissions:[],
                form:{},
                groupTbl: [
                    {
                        key: "name",
                        label: "Group Name",
                        sortable: false,
                    },
                    {
                        key: "permission",
                        sortable: false,
                    },
                    {
                        key: "tags",
                        sortable: false,
                    },
                    {
                        key: "actions",
                        sortable: false,
                    },
                ],
                loaded: false,
                perPage: 10,
                currentPage: 1,
                filter: null,
                filterOn: [],
                viewEntryOptions: [
                    { value: 5, text: '5' },
                    { value: 10, text: '10' },
                    { value: 20, text: '20' },
                    { value: 20, text: '50' },
                    { value: 20, text: '100' },
                ],
            }
        },
        methods: {
            onFiltered(filteredItems) {               
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
            getGroup()
            {
                axios.get(this.basePath+'api/admin/group/list',{ headers:this.adminHeaders })
                .then((res) => {
                    this.groups = res.data.data; 
                    this.loaded = true;
                })
                .catch((error) => {
                    console.log('Error:'+ error);               
                }).finally(() => {
                });
            },
            deleteRow(gid) {      
                this.displayAlert = false;                
                this.$bvModal.msgBoxConfirm("Are you sure to delete this group ?" ,{
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                noCloseOnBackdrop: true,
                noCloseOnEsc: true,
                centered: false
                }).then(value => {                     
                    if (String(value) == "true") {
                        this.loaded = false; 
                        axios.delete(process.env.VUE_APP_URL + 'api/admin/group/' + gid ,{ headers: this.adminHeaders})
                        .then(res => {
                            this.displayAlert = true, 
                            this.alertMessage = res.data.message; 
                            this.getGroup();                         
                        })
                        .catch(error => {
                            error => this.errors = error.response.data
                        });
                    }                    
                })
                .catch(err => {
                    console.log(err);
                });
                return false;
            },
        },
        mounted() {            
            this.getGroup();
        },
        computed: {
            rows() {
                return this.groups.length
            },
        }
    }
</script>
<style scoped>
.admin-area table i.fas.fa-users
{
    color: #002c59 !important
}
.table .btn-group-sm .btn-primary:hover i
{
    color: #fff !important
}
</style>